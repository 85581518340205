/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #2E4C6D;
  color: white;
}
.header .logo {
  font-size: 1.5em;
  font-weight: bold;
}
/* .header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}
.header nav ul li a {
  color: white;
  text-decoration: none;
} */
/* .header .get-started {
  background-color: #FF6B00;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}  */

p {
 color: #1E3A8A;
 }