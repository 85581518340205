.identity-and-goals {
  background-color: #f1f6f9;
  padding: 80px 0;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.text-content {

  flex: 1;
  padding-right: 50px;
}

h2 {
  font-size: 2.5rem;
  color: #1E3A8A !important;
  margin-bottom: 20px;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333333 !important;
}

.illustration {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goals-image {
  max-width: 100%;
  height: 500px;
}

@media (max-width: 1024px) {
  .content-wrapper {
    flex-direction: column;
  }

  .text-content {
    padding-right: 0;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 2.2rem;
  }

  .illustration {
    width: 50% !important;
    height: 50% !important;
  }
}

@media (max-width: 768px) {
  .identity-and-goals {
    padding: 60px 0;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .identity-and-goals {
    padding: 40px 0;
  }

  h2 {
    font-size: 1.8rem;
  }
}

.slogan {
  font-size: 1.3rem;
  font-weight: bold;
  color: #1E3A8A;
  margin-top: 20px;
  font-style: italic;
}