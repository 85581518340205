.our-clients {
  background: linear-gradient(135deg, #f6f9fc 0%, #e9f1f9 100%);
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}

.clients-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.our-clients h2 {
  font-size: 2.5rem;
  color: #1E3A8A !important;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
}

.our-clients > p {
  font-size: 1.1rem;
  color: #4B5563;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 50px;
}

.client-scroll {
  display: flex;
  overflow-x: auto;
  gap: 30px;
  padding: 20px 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.client-scroll::-webkit-scrollbar {
  height: 8px;
}

.client-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.client-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.client-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.client-card {
  flex: 0 0 auto;
  width: 280px;
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  scroll-snap-align: start;
}

.client-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.client-logo-container {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8fafc;
  border-radius: 15px;
  overflow: hidden;
}

.client-logo {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.client-title {
  font-size: 1.4rem;
  color: #2C3E50;
  margin-bottom: 10px;
  text-align: center;
}

.client-description {
  font-size: 1rem;
  color: #5D6D7E;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 768px) {
  .our-clients {
    padding: 80px 0;
  }

  .our-clients h2 {
    font-size: 2rem;
  }

  .our-clients > p {
    font-size: 1rem;
    margin-bottom: 40px;
  }

  .client-card {
    width: 240px;
    padding: 25px;
  }

  .client-logo-container {
    width: 80px;
    height: 80px;
  }

  .client-title {
    font-size: 1.2rem;
  }

  .client-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .our-clients {
    padding: 60px 0;
  }

  .our-clients h2 {
    font-size: 1.8rem;
  }

  .our-clients > p {
    font-size: 0.9rem;
    margin-bottom: 30px;
  }

  .client-card {
    width: 220px;
    padding: 20px;
  }

  .client-logo-container {
    width: 70px;
    height: 70px;
  }

  .client-title {
    font-size: 1.1rem;
  }

  .client-description {
    font-size: 0.85rem;
  }
}