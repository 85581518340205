.our-team {
  background-color: #f8fafc;
  padding: 100px 0;
}

.team-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.our-team h2 {
  font-size: 2.5rem;
  color: #1E3A8A;
  text-align: center;
  margin-bottom: 20px;
}

.our-team > p {
  font-size: 1.1rem;
  color: #4B5563;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 50px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.team-member-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.team-member-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.member-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.member-image img {
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top; /* This ensures the top of the image is always visible */
  transition: transform 0.3s ease;
}

.team-member-card:hover .member-image img {
  transform: scale(1.05);
}

.team-member-card h3 {
  font-size: 1.5rem;
  color: #1E3A8A;
  margin: 20px 0 5px;
}

.team-member-card h4 {
  font-size: 1rem;
  color: #4B5563;
  margin: 0 0 15px;
  font-weight: 500;
}

.team-member-card p {
  font-size: 0.9rem;
  color: #6B7280 !important;
  padding: 0 20px 20px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .our-team {
    padding: 80px 0;
  }

  .our-team h2 {
    font-size: 2rem;
  }

  .our-team > p {
    font-size: 1rem;
    margin-bottom: 40px;
  }

  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  .member-image {
  padding-top: 100%;
    position: relative;
  overflow: hidden;
    height: 200px;
        padding-top: 75%; /* Creates a 4:3 aspect ratio for mobile */

  }

  .team-member-card h3 {
    font-size: 1.3rem;
  }

  .team-member-card h4 {
    font-size: 0.9rem;
  }

  .team-member-card p {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .our-team {
    padding: 60px 0;
  }

  .our-team h2 {
    font-size: 1.8rem;
  }

  .our-team > p {
    font-size: 0.9rem;
    margin-bottom: 30px;
  }

  .team-grid {
    grid-template-columns: 1fr;
  }

  .member-image {
   padding-top: 66.67%; 
    height: 180px;
  }
}