.hero {
  background: linear-gradient(to bottom, #2E4C6D, #2E4C6D, #ced7e2);
  color: #fff;
  padding: 100px 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.hero-content {
  max-width: 100%;
  padding-left: 10%;
}

h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-weight: bold;
}

p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #333333 ;
}

#subTitle{
color: #ffffff !important;
}

.cta-button {
  background-color: #FF6B00;
  color: #fff ;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #E65A00;
}

.hero-image {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.hero-illustration {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 80px 20px 50px;
  }

  .hero-content {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1rem;
  }

  .hero-image {
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .hero-illustration {
    width: 100%;
    height: auto;
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 60px 15px 40px;
  }

  h1 {
    font-size: 2rem;
  }

  .cta-button {
    padding: 12px 24px;
    font-size: 1rem;
  }

  .hero-image {
    max-height: 250px;
  }

  .hero-illustration {
    max-height: 250px;
  }
}