.header {
  height: 60px;
  background-color: #ffffff;
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-container {
  height: 60px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-image {
  height: 50px;
}

.logo {
  color: #2E4C6D;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.code-icon {
  margin-left: 5px;
  color: #ff6b00;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.nav-menu ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 0.9em;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000000;
}

.menu-icon {
  font-size: 24px;
}

@media (max-width: 768px) {
  .header-container {
    justify-content: space-between; /* Changed from flex-start to space-between */
  }

  .menu-toggle {
    display: block;
    margin-right: 0; /* Remove right margin */
    order: -1; /* Move menu toggle to the left */
  }

  .logo {
    position: absolute; /* Position the logo absolutely */
    left: 50%; /* Move it to the center */
    transform: translateX(-50%); /* Adjust for the logo's width */
  }

  .nav-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    display: none;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .nav-menu.open {
    display: block;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}