/* Footer.css */


  
.footer {
  background-color: #2E4C6D;
  color: #ffffff !important;
  padding: 40px 0;
 
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.company-info,
.contact-info,
.legal-info {
  flex: 1;
  margin-bottom: 20px;
  min-width: 250px;
}

.company-info h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.company-info p,
.contact-info p,
.legal-info p {
  font-size: 0.9rem;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .company-info,
  .contact-info,
  .legal-info {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 30px 0;
  }
  
  .company-info h3 {
    font-size: 1.1rem;
  }

  .company-info p,
  .contact-info p,
  .legal-info p {
    font-size: 0.8rem;
    /* color: #ffffff !important; */
  }
}

.footer .footer-content .company-info p,
.footer .footer-content .contact-info p,
.footer .footer-content .legal-info p {
  color: #ffffff !important;
}