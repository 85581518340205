.trust-builders {
  background-color: #f8fafc;
  padding: 80px 0;
}

.trust-builders h2 {
  font-size: 2.5rem;
  color: #1E3A8A;
  text-align: center;
  margin-bottom: 50px;
}

.trust-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.trust-item {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.trust-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.trust-icon {
  font-size: 2.5rem;
  color: #1E3A8A;
  margin-bottom: 15px;
}

.trust-item h3 {
  font-size: 1.5rem;
  color: #2C3E50;
  margin-bottom: 15px;
}

.trust-item p {
  font-size: 1rem;
  color: #5D6D7E !important;
  line-height: 1.6;
}

@media (max-width: 992px) {
  .trust-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .trust-builders {
    padding: 60px 0;
  }

  .trust-builders h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .trust-grid {
    grid-template-columns: 1fr;
  }

  .trust-item {
    padding: 25px;
  }

  .trust-icon {
    font-size: 2rem;
  }

  .trust-item h3 {
    font-size: 1.3rem;
  }

  .trust-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .trust-builders {
    padding: 40px 0;
  }

  .trust-builders h2 {
    font-size: 1.8rem;
  }

  .trust-item {
    padding: 20px;
  }
}