.technologies {
  background-color: #f8fafc;
  padding: 80px 0;
}

.tech-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.technologies h2 {
  font-size: 2.5rem;
  color: #1E3A8A;
  text-align: center;
  margin-bottom: 20px;
}

.technologies > p {
  font-size: 1.1rem;
  color: #4B5563 !important;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 50px;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 30px;
  justify-items: center;
}

.tech-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.tech-icon svg {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.tech-icon:hover svg {
  transform: scale(1.1);
}

.tech-icon span {
  font-size: 0.9rem;
  color: #4B5563;
}

/* Specific colors for each technology */
.tech-icon:nth-child(1) svg { color: #61DAFB; } /* React */
.tech-icon:nth-child(2) svg { color: #339933; } /* Node.js */
.tech-icon:nth-child(3) svg { color: #3776AB; } /* Python */
.tech-icon:nth-child(4) svg { color: #F7DF1E; } /* JavaScript */
/* .tech-icon:nth-child(5) svg { color: #3178C6; } */
 /* TypeScript */
.tech-icon:nth-child(6) svg { color: #FF9900; } /* AWS */
.tech-icon:nth-child(7) svg { color: #2496ED; } /* Docker */
.tech-icon:nth-child(8) svg { color: #02569B; } /* Flutter */
.tech-icon:nth-child(9) svg { color: #47A248; } /* MongoDB */ 

@media (max-width: 768px) {
  .technologies {
    padding: 60px 0;
  }

  .technologies h2 {
    font-size: 2rem;
  }

  .technologies > p {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .tech-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .tech-icon svg {
    width: 50px;
    height: 50px;
  }

  .tech-icon span {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .tech-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .technologies h2 {
    font-size: 1.8rem;
  }

  .technologies > p {
    font-size: 0.9rem;
  }

  .tech-icon svg {
    width: 40px;
    height: 40px;
  }
}