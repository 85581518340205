.contact-form {
  padding: 4rem 2rem;
  background-color: #F0F7FF;
  text-align: center;
}

.contact-form h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #1E3A8A;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #1E3A8A;
}

.contact-form textarea {
  resize: vertical;
  min-height: 150px;
}

.contact-form button {
  background-color: #FF6B00;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  margin-top: 1rem;
  align-self: center;
  width: 200px;
}

.contact-form button:hover {
  background-color: #E65A00;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 2rem 1rem;
  }

  .contact-form h2 {
    font-size: 2rem;
  }

  .contact-form form {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .contact-form button {
    width: 100%;
  }
}
